// npm
import React, { memo } from 'react'
import {
  Box,
  BoxProps,
  Container,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'

// atoms
import SmallDivider from '@atoms/dividers/SmallDivider'

export interface SectionLeadTextProps extends BoxProps {
  smallHeading?: string
  text: string
}

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  textAlign: 'center',
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
}))

const SectionLeadText = ({
  text,
  smallHeading,
  ...props
}: SectionLeadTextProps) => {
  const theme = useTheme()
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))
  return (
    <Wrapper component="section" {...props}>
      <Container maxWidth={lgDown ? 'md' : 'lg'}>
        {smallHeading && (
          <Box
            typography="xsHeading"
            color="primary.main"
            component="h2"
            mt={0}
            mb={3}
          >
            {smallHeading}
          </Box>
        )}
        <Box typography="leadText" mb={6}>
          {text}
        </Box>
        <SmallDivider sx={{ margin: '0 auto' }} />
      </Container>
    </Wrapper>
  )
}

export default memo(SectionLeadText)
