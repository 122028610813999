// npm
import React, { memo } from 'react'
import { Divider, DividerProps, styled } from '@mui/material'

const StyledDivider = styled(Divider)(({ theme }) => ({
  width: '20px',
}))

const SmallDivider = (props: DividerProps) => {
  return <StyledDivider {...props} />
}

export default memo(SmallDivider)
